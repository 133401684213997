<template>
  <h3>Latest News</h3>
  <h4>Ver3.37E11 released</h4>
  <h5>April 26th, 2017</h5>
  <p>
    Import function supports full TLS, PEAP, TTLS. It fully supports HTTP proxy
    and DNS change in EAP<br /><a
      href="https://play.google.com/store/apps/details?id=jp.co.task_connect.sws01"
      >Read more</a
    >
  </p>
  <p></p>
  <h4>Ver3.37E10 released</h4>
  <h5>April 25th, 2017</h5>
  <p>Add update function to import.<br /><a href="#">Read more</a></p>
  <p></p>
  <h4>Blog Launched</h4>
  <h5>March 4th, 2017</h5>
  <p>
    Introduction and Tips, etc of Secure &amp; Useful Wifi switcher.<br /><a
      href="#"
      >Read more</a
    >
  </p>
</template>