<template>
  <h1>特定商取引法に基づく表記</h1>
  <table style="width: 100%; border-spacing: 0">
    <tr>
      <th>Item</th>
      <th>Description</th>
    </tr>
    <tr>
      <td>商品名</td>
      <td>Secure &amp; Useful Wifi switcher</td>
    </tr>
    <tr>
      <td>商品URL</td>
      <td>
        https://play.google.com/store/apps/details?id=jp.co.task_connect.sws01
      </td>
    </tr>
    <tr>
      <td>販売業者名</td>
      <td>株式会社タスク・コネクト</td>
    </tr>
    <tr>
      <td>代表責任者名</td>
      <td>木下 雅博</td>
    </tr>
    <tr>
      <td>所在地</td>
      <td>〒234-0056<br />神奈川県横浜市港南区野庭町1527-20</td>
    </tr>
    <tr>
      <td>電話番号</td>
      <td>050-3377-9139</td>
    </tr>
    <tr>
      <td>電話受付時間</td>
      <td>9:00～17:00</td>
    </tr>
    <tr>
      <td>メールアドレス</td>
      <td>info@task-connect.co.jp</td>
    </tr>
    <tr>
      <td>ホームページURL</td>
      <td>https://www.task-connect.co.jp/</td>
    </tr>
    <tr>
      <td>販売価格</td>
      <td>アプリ内のメニューの「ライセンスの購入」からご確認ください</td>
    </tr>
    <tr>
      <td>商品代金以外の必要料金</td>
      <td>消費税</td>
    </tr>
    <tr>
      <td>お届け時期</td>
      <td>入金確認後、直ちに商品を発送いたします。</td>
    </tr>
    <tr>
      <td>お支払方法</td>
      <td>Google Wallet</td>
    </tr>
    <tr>
      <td>お申込みの有効期限</td>
      <td>
        ７日以内にお願いいたします。<br />７日間入金がない場合は、キャンセルとさせていただきます。
      </td>
    </tr>
    <tr>
      <td>返品・交換・キャンセル等</td>
      <td>Google Playの規約に準拠いたします</td>
    </tr>
    <tr>
      <td>返品期限</td>
      <td>Google playの規約に準拠いたします</td>
    </tr>
    <tr>
      <td>返品送料</td>
      <td>Google playの規約に準拠いたします</td>
    </tr>
  </table>
</template>