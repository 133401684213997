<template>
  <h1>Contact Us</h1>
  <table style="width: 100%; border-spacing: 0">
    <tr>
      <th>Item</th>
      <th>Description</th>
    </tr>
    <tr>
      <td>Company name</td>
      <td>Task Connect Co. Ltd.</td>
    </tr>
    <tr>
      <td>Address</td>
      <td>1527-20<br />Noba Konan Yokohama Japan 234-0056</td>
    </tr>
    <tr>
      <td>Phone</td>
      <td>(+81)50-3377-9139</td>
    </tr>
    <tr>
      <td>Email</td>
      <td>info@task-connect.co.jp</td>
    </tr>
    <tr>
      <td>Corporate Number</td>
      <td>3020001099119</td>
    </tr>
  </table>
</template>