<template>
  Copyright &copy; Task Connect Co. Ltd. |
  <router-link to="/privacy" @click="set(-1)">PRIVACY</router-link> |
  <router-link to="/acts" @click="set(-1)"
    >the Act on Specified Commercial Transactions</router-link
  >
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    set: function (pos) {
      sessionStorage.setItem("header_btn_clicked", pos);
    },
  },
});
</script>
