<template>
  <!-- insert the page content here -->
  <h1>We create value from the connection.</h1>
  <p>
    By linking various things and people through software, we will realize new
    possibilities that we have never before...
  </p>
  <p>
    Currently mainly, we aim to be able for people to use Wi-Fi safely and
    comfortably by congestion avoidance which disturbing Wi-Fi connectivity, and
    ensuring Wi-Fi security which ensure connectivity including VPN.
  </p>
  <h2>
    <a
      href="https://play.google.com/store/apps/details?id=jp.co.task_connect.sws01"
      >Secure &amp; Useful Wifi switcher</a
    >
  </h2>
  <p>
    Secure &amp; Useful Wifi switcher is an application to securely use Wi-Fi
    anywhere in the world. By using the cloud to share anonymized data, we
    realize the safety and convenience of the highest level of Wi-Fi.
  </p>
  <ul>
    <li>
      WiFi switching according to line and communication quality (It supports
      SSID roaming)
    </li>
    <li>Enhanced VPN security</li>
    <li>Measures against man-in-a-middle attacks such as unauthorized AP</li>
    <li>
      Secure free WiFi auto registration (2346 free WiFi from all over the world
      registered)
    </li>
    <li>Public DNS and HTTP proxy settings linked to Wi-Fi connection.</li>
    <li>
      Batch import / export of SSID (CSV format, support OPEN,WEP,WPA/WPA2 on
      both functions. PEAP/TTLS/TLS is supported by import only)
    </li>
  </ul>
</template>



