import { createRouter, createWebHistory } from 'vue-router'
import Home from './components/Home'
import About from './components/About'
import Works from './components/Works'
import Contact from './components/Contact'
import Privacy from './components/Privacy'
import Acts from './components/Acts'

const routes = [
    {
        path: '/',
        name: "Home",
        component: Home
    }, {
        path: '/about',
        name: "About",
        component: About
    }, {
        path: '/works',
        name: "Works",
        component: Works
    }, {
        path: '/contact',
        name: "Contact",
        component: Contact
    }, {
        path: '/privacy',
        name: "Privacy",
        component: Privacy
    }, {
        path: '/acts',
        name: "Acts",
        component: Acts

    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
