<template>
  <div id="header">
    <div id="logo">
      <div id="logo_text">
        <!-- class="logo_colour", allows you to change the colour of the text -->
        <h1>
          <router-link to="/"
            >Task <span class="logo_colour">Connect</span></router-link
          >
        </h1>
        <h2>A company that realizes good things</h2>
      </div>
    </div>
    <div id="menubar">
      <ul id="menu">
        <!-- put class="selected" in the li tag for the selected page - to highlight which page you're on -->
        <li v-bind:class="{ selected: header_btn_pos === 0 }">
          <router-link to="/" @click="set(0)">Home</router-link>
        </li>
        <li v-bind:class="{ selected: header_btn_pos === 1 }">
          <router-link to="/works" @click="set(1)">Works</router-link>
        </li>
        <li><a href="https://blog.task-connect.co.jp/">Blog</a></li>
        <li>
          <a
            href="https://groups.google.com/d/forum/qanda-forum---secure-and-useful-wifi-switcher/"
            >Support</a
          >
        </li>
        <li v-bind:class="{ selected: header_btn_pos === 2 }">
          <router-link to="/contact" @click="set(2)">Contact Us</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      header_btn_pos: 0,
    };
  },
  methods: {
    set: function (pos) {
      this.header_btn_pos = Number(pos);
    },
  },
});
</script>