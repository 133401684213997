<template>
  <Header />
  <div id="site_content">
    <div class="sidebar"><Side /></div>
    <div id="content"><router-view /></div>
  </div>
  <div id="footer"><Footer /></div>
</template>

<script>
import Header from "./components/Header";
import Side from "./components/Side";
import Footer from "./components/Footer";

export default {
  name: "App",
  components: { Header, Side, Footer },
};
</script>

<style>
html {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font: normal 0.8em "trebuchet ms", arial, sans-serif;
  background: #f0efe2;
  color: #777;
}

p {
  padding: 0 0 20px 0;
  line-height: 1.7em;
}

img {
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font: normal 175% "century gothic", arial, sans-serif;
  color: #43423f;
  margin: 0 0 15px 0;
  padding: 15px 0 5px 0;
}

h2 {
  font: normal 175% "century gothic", arial, sans-serif;
  color: #1293ee;
}

h4,
h5,
h6 {
  margin: 0;
  padding: 0 0 5px 0;
  font: normal 120% arial, sans-serif;
  color: #1293ee;
}

h5,
h6 {
  font: italic 95% arial, sans-serif;
  padding: 0 0 15px 0;
  color: #000;
}

h6 {
  color: #362c20;
}

a,
a:hover {
  outline: none;
  text-decoration: underline;
  color: #f05a00;
}

a:hover {
  text-decoration: none;
}

.left {
  float: left;
  width: auto;
  margin-right: 10px;
}

.right {
  float: right;
  width: auto;
  margin-left: 10px;
}

.center {
  display: block;
  text-align: center;
  margin: 20px auto;
}

blockquote {
  margin: 20px 0;
  padding: 10px 20px 0 20px;
  border: 1px solid #e5e5db;
  background: #fff;
}

ul {
  margin: 2px 0 22px 17px;
}

ul li {
  list-style-type: circle;
  margin: 0 0 6px 0;
  padding: 0 0 4px 5px;
}

ol {
  margin: 8px 0 22px 20px;
}

ol li {
  margin: 0 0 11px 0;
}

#main,
#logo,
#menubar,
#site_content,
#footer {
  margin-left: auto;
  margin-right: auto;
}

#header {
  background: #f79f00;
  height: 240px;
}

#logo {
  width: 825px;
  position: relative;
  height: 168px;
  background: #8b0073 url(./assets/logo.png) no-repeat;
}

#logo #logo_text {
  position: absolute;
  top: 20px;
  left: 0;
}

#logo h1,
#logo h2 {
  font: normal 300% "century gothic", arial, sans-serif;
  border-bottom: 0;
  text-transform: none;
  margin: 0;
}

#logo_text h1,
#logo_text h1 a,
#logo_text h1 a:hover {
  padding: 22px 0 0 0;
  color: #fff;
  letter-spacing: 0.1em;
  text-decoration: none;
}

#logo_text h1 a .logo_colour {
  color: #f05a00;
}

#logo_text h2 {
  font-size: 100%;
  padding: 4px 0 0 0;
  color: #fff;
}

#menubar {
  width: 877px;
  height: 72px;
  padding: 0;
  background: #5b2660 url(./assets/menu.png) repeat-x;
}

ul#menu,
ul#menu li {
  float: left;
  margin: 0;
  padding: 0;
}

ul#menu li {
  list-style: none;
}

ul#menu li a {
  letter-spacing: 0.1em;
  font: normal 100% "lucida sans unicode", arial, sans-serif;
  display: block;
  float: left;
  height: 37px;
  padding: 29px 26px 6px 26px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
}

ul#menu li a:hover,
ul#menu li.selected a,
ul#menu li.selected a:hover {
  color: #fff;
  background: #3f1b41 url(./assets/menu_select.png) repeat-x;
}

#site_content {
  width: 837px;
  overflow: hidden;
  margin: 0 auto 0 auto;
  padding: 20px 24px 20px 37px;
  background: #fff url(./assets/content.png) repeat-y;
}

.sidebar {
  float: right;
  width: 190px;
  min-height: 500px;
  height: auto;
  padding: 0 15px 20px 15px;
}

.sidebar ul {
  width: 178px;
  padding: 4px 0 0 0;
  margin: 4px 0 30px 0;
}

.sidebar li {
  list-style: none;
  padding: 0 0 7px 0;
}

.sidebar li a,
.sidebar li a:hover {
  padding: 0 0 0 40px;
  display: block;
  background: transparent url(./assets/link.png) no-repeat left center;
}

.sidebar li a.selected {
  color: #444;
  text-decoration: none;
}

#content {
  text-align: left;
  width: 595px;
  height: auto;
  padding: 0;
}

#content ul {
  margin: 2px 0 22px 0px;
}

#content ul li {
  list-style-type: none;
  background: url(./assets/bullet.png) no-repeat;
  margin: 0 0 6px 0;
  padding: 0 0 4px 25px;
  line-height: 1.5em;
}

#footer {
  width: 878px;
  font: normal 100% "lucida sans unicode", arial, sans-serif;
  height: 33px;
  padding: 24px 0 5px 0;
  text-align: center;
  background: #29425e url(./assets/footer.png) repeat-x;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

#footer a {
  color: #fff;
  text-decoration: none;
}

#footer a:hover {
  color: #fff;
  text-decoration: underline;
}

.search {
  color: #5d5d5d;
  border: 1px solid #bbb;
  width: 134px;
  padding: 4px;
  font: 100% arial, sans-serif;
}

#colours {
  height: 0px;
  text-align: right;
  padding: 66px 16px 0px 300px;
}

.form_settings {
  margin: 15px 0 0 0;
}

.form_settings p {
  padding: 0 0 4px 0;
}

.form_settings span {
  float: left;
  width: 200px;
  text-align: left;
}

.form_settings input,
.form_settings textarea {
  padding: 5px;
  width: 299px;
  font: 100% arial;
  border: 1px solid #e5e5db;
  background: #fff;
  color: #47433f;
}

.form_settings .submit {
  font: 100% arial;
  border: 1px solid;
  width: 99px;
  margin: 0 0 0 212px;
  height: 33px;
  padding: 2px 0 3px 0;
  cursor: pointer;
  background: #f05a00;
  color: #fff;
}

.form_settings textarea,
.form_settings select {
  font: 100% arial;
  width: 299px;
}

.form_settings select {
  width: 310px;
}

.form_settings .checkbox {
  margin: 4px 0;
  padding: 0;
  width: 14px;
  border: 0;
  background: none;
}

.separator {
  width: 100%;
  height: 0;
  border-top: 1px solid #d9d5cf;
  border-bottom: 1px solid #fff;
  margin: 0 0 20px 0;
}

table {
  margin: 10px 0 30px 0;
}

table tr th,
table tr td {
  background: #3b3b3b;
  color: #fff;
  padding: 7px 4px;
  text-align: left;
}

table tr td {
  background: #f0efe2;
  color: #47433f;
  border-top: 1px solid #fff;
}
</style>
