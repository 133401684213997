<template>
  <h1>Products</h1>
  <p>Our product list currently handled</p>
  <h2>
    <a
      href="https://play.google.com/store/apps/details?id=jp.co.task_connect.sws01"
      >Secure &amp; Useful Wifi switcher</a
    >
  </h2>
  <table style="width: 100%; border-spacing: 0">
    <tr>
      <th>Item</th>
      <th>Description</th>
    </tr>
    <tr>
      <td>Supported OS</td>
      <td>Android 5.0 and more</td>
    </tr>
    <tr>
      <td>Latest Version</td>
      <td>3.36E1</td>
    </tr>
    <tr>
      <td>Size</td>
      <td>8.25MB</td>
    </tr>
  </table>
  <p>
    VPN security enhancement of this application complements OpenVPN Connect,
    this application provides the following functions to enhance privacy
    protection and security.
  </p>
  <ul>
    <li>Quick VPN on / off</li>
    <li>
      Block communication immediately when VPN connection is disconnected to
      prevent leakage of communication by raw IP
    </li>
    <li>
      By switching to Public DNS, VPN can be used even if DNS assigned by DHCP
      can not be accessed or name resolution can not be done
    </li>
  </ul>
  <p>
    Secure & Useful Wifi switcher is an application to securely use Wi-Fi
    anywhere in the world. It support SSID roaming with no root required. Each
    Wi-Fi AP can be identified by the combinatiion of SSID and MAC address. By
    using cloud service to share anonymized data, you can avoid congestion and
    improve productivity.
  </p>
  <p>
    It also support HTTP proxy settings linked to Wi-Fi connection. Up to 3
    proxy profiles can be registered.
  </p>
  <p>
    Automatic logon is also supported by Hotspot authentication by WISPr. You
    can start any application automatically in conjunction with the Wi - Fi
    connection selected in advance on the setting screen.
  </p>
  <p>
    It is also possible to log in with one tap by automatically launching the
    authentication application such as "Japan Connected Free Wi - Fi" or "Youth
    Hotspot" in Germany.
  </p>
  <p>
    Furthermore, there are functions that other applications do not have, such
    as Wi-Fi switching according to quality using cloud service, visualization
    of congestion situation according to network delay and communication
    situation.
  </p>
  <p>
    Besides, it works in conjunction with Bluetooth connection to automate Wi-Fi
    tethering on and off, display Wi-Fi tethering connection information with QR
    code, etc.
  </p>
  <p>
    There is a trial period of 30 days. After 30 days, you can select a free
    plan that can be used for free with points and a pay plan based on billing.
  </p>
  <p>
    It is an application that can easily upgrade Wifi environment at work or
    private time, at home or on the go. Please try using it.
  </p>
</template>